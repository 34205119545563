<template>
  <div class="responsive-bar">
    <Footer></Footer>
    <div class="scan-qr">
      <div class="">
        <h1 class="header-text">
          <div class="topic">{{ $t("scan.scanqr") }}</div>
        </h1>
      </div>
      <div class="pt-4">
        <div
          class="d-flex justify-content-center align-items-center"
          v-if="!isNative"
        >
          <div class="camera-frame position-relative">
            <div class="camera-frame-corner"></div>
            <qrcode-stream @decode="onDecode" @init="onInit" v-if="!error">
            </qrcode-stream>
            <div v-else>{{ error }}</div>
          </div>
        </div>
        <div class="line-break"></div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomQrName from "@/util/CustomQrName.json";
import Footer from "@/components/Footer.vue";
import { QrcodeStream } from "vue-qrcode-reader";
import Swal from "sweetalert2";
import base62 from "base62/lib/ascii";
import { stage } from "../../util/backend";
const webStage = stage();
const isProductionStage = webStage === "pre-prod" || webStage === "prod";

export default {
  components: { QrcodeStream, Footer },
  data() {
    return {
      error: null,
      isNative: false,
    };
  },
  created() {
    if (window.isNativeApp) {
      this.isNative = true;
    }
  },
  methods: {
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        }
      }
    },
    async onDecode(decodedString) {
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      const checkPattern = /(^https?:\/\/verscan.com\/check.*)/g;
      const checkPatternType2 = /(^vers.ly.*)/g;

      let parsedDecodedString = null;

      if (checkPattern.test(decodedString)) {
        const param = decodedString.replace("https://verscan.com/check/", "");
        const qrCode = encodeURIComponent(param);
        parsedDecodedString = qrCode;
      } else if (checkPatternType2.test(decodedString)) {
        const param = decodedString.match(/([^/]+)$/);
        const baseNumber = param[0];
        let result = "";
        if (baseNumber.slice(0, 1) == 0) {
          if (id_company === 323 && isProductionStage) {
            // Bangkok Mental Health Hospital only decoded
            const firstTwoChars = baseNumber.slice(0, 2);
            const removeFirst2 = baseNumber.substring(2);
            const decodedNumber = base62.decode(removeFirst2);
            const paddedNumber = String(decodedNumber).padStart(6, "0");
            const customName = CustomQrName[firstTwoChars];
            if (customName) {
              result = `${CustomQrName[firstTwoChars]}${paddedNumber}`;
            } else {
              return this.showInvalidQrCodeAlert();
            }
          }
        } else {
          if ((id_company === 52 || id_company === 212) && isProductionStage) {
            const decodedNumber = base62.decode(baseNumber);
            const paddedNumber = String(decodedNumber).padStart(7, "0");
            result = `VTN${paddedNumber}`;
          } else {
            const decodedNumber = base62.decode(baseNumber);
            const paddedNumber = String(decodedNumber).padStart(11, "0");
            result = String(paddedNumber)
              .splice(3, 0, "-")
              .splice(8, 0, "-");
          }
        }
        parsedDecodedString = result;
      } else if (
        (id_company === 257 || id_company === 258) &&
        decodedString &&
        isProductionStage
      ) {
        // --------- for 2 companies of bualuang -----------
        const qrCode = encodeURIComponent(decodedString);
        parsedDecodedString = qrCode;
      } else {
        this.showInvalidQrCodeAlert();
      }

      if (!parsedDecodedString) return this.showInvalidQrCodeAlert();

      this.$router.push({
        name: "ScanMaintenanceAssetCheck",
        params: { qrAssetId: parsedDecodedString },
        query: { ...this.$route.query },
      });
    },
    showInvalidQrCodeAlert() {
      Swal.fire({
        title: this.$t("dialog.wrong_qr"),
        text: this.$t("dialog.wrong_qr_dt"),
        timer: 3000,
        imageUrl: "https://static.verscan.com/verscan-v1/images/sweet-alert-alert.svg",
        confirmButtonText: this.$t("btn.close"),
        imageHeight: 48,
        imageWidth: 48,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header-text {
  padding-top: 58px;
  height: 173px;
  background: transparent linear-gradient(180deg, #ffa800 0%, #ea7509 100%);
  margin: auto;

  h1 {
    font: normal normal bold 44px/48px Mitr;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    // margin-bottom: 15rem;
  }
}

.camera-frame {
  height: 300px;
  width: 300px;
  border: 1px solid black;
  @media only screen and (max-width: 420px) {
    height: 280px;
    width: 280px;
  }
  @media only screen and (min-width: 320px) {
    width: 255px;
    height: 285px;
  }
  @media only screen and (min-width: 375px) {
    width: 255px;
    height: 285px;
  }
  @media only screen and (min-width: 768px) {
    width: 255px;
    height: 285px;
  }
}

.camera-frame-corner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  z-index: 2;
  background: linear-gradient(to right, black 4px, transparent 4px) 0 0,
    linear-gradient(to right, black 4px, transparent 4px) 0 100%,
    linear-gradient(to left, black 4px, transparent 4px) 100% 0,
    linear-gradient(to left, black 4px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, black 4px, transparent 4px) 0 0,
    linear-gradient(to bottom, black 4px, transparent 4px) 100% 0,
    linear-gradient(to top, black 4px, transparent 4px) 0 100%,
    linear-gradient(to top, black 4px, transparent 4px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
</style>
